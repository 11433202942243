import React from 'react'
import '../styles/styles.css'

const App = () => (
  <div className="main">
    <div className="main-header">
      <div className="main-title">LUCAS JOHNSON</div>
      <div className="main-subtitle">Front End Engineer</div>
      <div className="main-links">
        <a href="https://github.com/lucaswjohnson" target="_blank" rel="noopener noreferrer">
          <img src={`${process.env.PUBLIC_URL}/icons/GitHub.svg`} alt="GitHub" />
        </a>
        <a href="https://www.linkedin.com/in/lucaswjohnson/" target="_blank" rel="noopener noreferrer">
          <img src={`${process.env.PUBLIC_URL}/icons/LinkedIn.png`} alt="LinkedIn" />
        </a>
      </div>
    </div>
  </div>
)

export default App
